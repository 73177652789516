export default function ProjectCard({title, link, color}) {
    const colorClasses = {
        blue: {
            border: 'border-blue-400',
            gradient: 'bg-gradient-to-tl from-black to-blue-500',
        },
        red: {
            border: 'border-red-400',
            gradient: 'bg-gradient-to-tl from-black to-red-500',
        },
        green: {
            border: 'border-green-400',
            gradient: 'bg-gradient-to-tl from-black to-green-500',
        },
        yellow: {
            border: 'border-yellow-400',
            gradient: 'bg-gradient-to-tl from-black to-yellow-500',
        },
    };

    // Destructure the classes based on the provided color
    const {border, gradient} = colorClasses[color] || colorClasses.blue; // Fallback to blue if color not found

    return (

        <a href={link}
           className={`flex flex-1 items-center justify-between p-4 rounded-xl ${border} bg-background mb-4 border-2 space-x-8`}>
            <div className="flex items-center">
                {/* Gradient icon */}
                <div className={`w-6 h-8 rounded-lg ${gradient}`}></div>
                {/* Title */}
                <h2 className="text-white text-lg ml-4">{title}</h2>
            </div>
            {/* Button that acts as a link */}
        </a>
    );
}
