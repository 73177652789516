import {useState} from "react";
import {ProjectCard} from "../Cards";

export default function LeftMenu() {
    const [isVisible, setIsVisible] = useState(true); // State to manage visibility

    const toggleMenu = () => {
        setIsVisible((prev) => !prev); // Toggle visibility directly
    };

    // Render nothing if not visible
    if (!isVisible) return null;

    return (
        <div className="absolute top-0 left-0">
            {/* Close button */}


            {/* Left menu */}
            <div className="bg-cardbackground border-primary border h-screen w-96 p-8 animate-fade-left">
                <button
                    onClick={toggleMenu}
                    className="w-full bg-primary hover:scale-90 active:scale-105 hover:bg-background hover:text-primary hover:border-2 hover:border-primary text-background text-xl rounded p-2 mb-4"
                >
                    &#10005; {/* Close icon */}
                </button>
                <div className="grid grid-cols-1 gap-2 pt-8">
                    <ProjectCard title="Token Creation DApp" link="/create-token" color="blue"/>
                    <ProjectCard title="Token Lock DApp" link="/lock-token" color="red"/>
                    <ProjectCard title="Stake DApp" link="/stakes" color="green"/>
                    <ProjectCard title="Sale DApp" link="/sale" color="yellow"/>
                </div>
            </div>
        </div>
    );
}
