import React from "react";
import "./css/Timeline.css";

const timelineData = [
    {
        date: "2024 Q4",
        title: "Pre-Sale",
        description:
            "Pre-Sale, Dex Token Listing, Liquidity Adding, Cmc And Coingecko Listing, Binance Labs Grant Application, Top Tier Global Marketing, Stake Program, Strategic Partnership, Avalanche Grant Application, Burn, Buyback, AI Audit Dapp Start, AI KYC Dapp Start, Global Partnerships, Stake Dapp, Token Creation Dapp, Lock Dapp",
        accentColor: "#00eaff",
    },
    {
        date: "2025 Q1",
        title: "Top Tier Marketing",
        description:
            "Tier 1 Cex Listing, Sony Blockchain Grant Application, Burn, Buyback, Cold Wallets Partnership Application, Top Tier Cex Partnerships, Top Tier Dex Partnerships, Web3D Dex Start, AI Cadcad Dapp, AI Rekt Dapp, AI Dapp Market Place, Web3D Grant Program for Influencers, Web3D Grant Programs for AI Developers, Web3D Grant Programs for Blockchain Developers, Ambassador Program, Global Partnerships, Collaborations with Universities for Students Internships, Ton Blockchain Grant Application, Arbitrum Blockchain Grant Application, Chainlink Grant Application",
        accentColor: "#ef4444",
    },
    {
        date: "2025 Q2",
        title: "Coinbase Grant Application",
        description:
            "Logic-Based Smart Contracts for RWA, Data Science, and Custody, Burn, Buyback, Top Tier Marketing, Tier 1 Cex Listing, Top Tier Cex Partnerships, Top Tier Dex Partnerships, Web3D Grant Program for Influencers, Web3D Grant Program for Community, Web3D Charity Foundation for SMA patients and children in need, AI Whitepaper Creator Dapp, AI Tokenomics Creator Dapp, AI Roadmap Creator Dapp, AI Dapp Creator Dapp",
        accentColor: "#22c55e",
    },
    {
        date: "2025 Q3",
        title: "AI Agents for Market and Trading Strategy",
        description:
            "EVM Layer 2 Web3D TestNet, DePin Integration Tests, Web3D Grant Programs for Developers, Web3D Grants Program for Influencers, Burn, Buyback, Top Tier Marketing, Tier 1 Cex Listing, Web3D Research Center for Undergraduate Students, Global Partnerships",
        accentColor: "#1B5F8C",
    },
    {
        date: "2025 Q4",
        title: "AI Agents for Market and Trading Strategy",
        description:
            "EVM Layer 2 Web3D TestNet, DePin Integration Tests, Burn, Buyback, Global Partnerships",
        accentColor: "#4CADAD",
    },
];

const Roadmap = () => {
    return (
        <div className="timeline" style={{marginBottom: "50px"}}>
            <h1 class="roadmap-title text-primary text-3xl lg:text-6xl font-main mb-14 mt-5">
                Web3D Roadmap
            </h1>
            <div className="timeline-container">
                {/* <h1 className="timeline-title"> Start </h1> */}
                <ul className="timeline">
                    {timelineData.map((item, index) => (
                        <li key={index} style={{"--accent-color": item.accentColor}}>
                            <div className="date">{item.date}</div>
                            <div className="contect_box">
                                <div className="title">{item.title}</div>
                                <div className="descr">{item.description}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Roadmap;
