import './App.css';
import {Route, Routes} from 'react-router-dom';
import {Home} from "./pages"
import Header from "./components/Sections/header";
import Grants from "./pages/grants";
import { Buffer } from 'buffer';
import StarsCanvas from './components/Canvas/StarCanvas';
import Staking from './pages/staking';


function App() {
    window.Buffer = Buffer;

    return (
        <div>
           <Header />
           
            <div className="bg-background min-h-screen p-8 lg:p-16 lg:pt-32 ">
            <StarsCanvas />

                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/stakes" element={<Staking/>}/>
                    <Route exact path="/grants" element={<Grants/>}/>
                </Routes>
            </div>
        </div>

    );
}

export default App;
