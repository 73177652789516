import {useState} from 'react';

export default function Backers() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const backers = [
        {src: "/images/web3/bsc.png", alt: "BSC", name: "BSC"},
        {src: "/images/web3/eth.png", alt: "Ethereum", name: "Ethereum"},
        {src: "/images/web3/polygon.png", alt: "Polygon", name: "Polygon"},
        {src: "/images/web3/base.png", alt: "Base", name: "Base"},
        {src: "/images/web3/avax.png", alt: "Avax", name: "Avalanche"},
        {src: "/images/web3/taskon.png", alt: "TaskOn", name: "TaskOn"},
        {src: "/images/web3/gempad.png", alt: "Gempad", name: "Gempad"},
        {src: "/images/web3/bitgert.png", alt: "Bitgert", name: "Bitgert"},
        {src: "/images/web3/thirdwave.png", alt: "Thirdwave", name: "Thirdwave"},
        {src: "/images/web3/mogul.png", alt: "Mogul", name: "Mogul"},
    ];

    return (
        <div className="flex flex-col items-center space-y-12 ">
            {/* Text */}
            <p className="bg-gradient-to-b from-white via-gray-300 to-gray-500 inline-block text-transparent bg-clip-text text-xl italic font-main font-light text-center">
                Trusted and Backed by Many Companies
            </p>

            {/* Backers */}
            <div className="grid grid-cols-2 gap-x-8 gap-y-4 justify-center w-full lg:w-2/3">
                {backers.map((backer, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <div
                            className={`flex flex-col transition-transform duration-300 ease-in-out transform delay-75 border-primary border-2 p-3 rounded-lg justify-center items-center shadow-md hover:shadow-lg w-full h-24`}
                        >
                            <div
                                className={`flex flex-col items-center justify-center h-full transition-all duration-300 ease-in-out ${
                                    hoveredIndex === index ? 'translate-y-[-10px]' : 'translate-y-0'
                                }`}
                            >
                                <img
                                    src={backer.src}
                                    alt={backer.alt}
                                    className={` h-12 transition-transform duration-400 pt-2`}
                                />

                                {/* Conditional rendering of text logo with animation */}
                                <p
                                    className={`mt-2 text-sm font-medium text-white font-main transition-opacity duration-300 transform ${
                                        hoveredIndex === index ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'
                                    }`}
                                >
                                    {backer.name}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
