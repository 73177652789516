import React from "react";

import { MovingBorderButton } from "../Cards/MovingBorder";
import { workExperience } from "../../utils/data";

const News = () => {
    return (
        <div className="w-full">
            <h1 class="tokenomics-title text-primary text-3xl lg:text-6xl font-main mb-5 mt-5">
                News
            </h1>


            <div className="w-full mt-12 grid grid-cols-6 max-md:grid-cols-1 gap-10">
                {workExperience.map((card) => (
                    <MovingBorderButton
                        key={card.id}
                        duration={Math.floor(Math.random() * 10000) + 10000}
                        borderRadius="1.75rem"
                        style={{
                            background: "#191A1C",
                            backgroundColor:
                                "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
                            borderRadius: `calc(1.75rem* 0.96)`,
                        }}
                        // remove bg-white dark:bg-slate-900
                        className="flex-1  text-black dark:text-white border-neutral-200 dark:border-slate-800"
                    >
                        <div className="flex lg:flex-row flex-col lg:items-center rounded-md p-3 py-2 md:p-2 lg:p-6 gap-2">
                            <div className="lg:ms-5">
                                <div className="flex flex-row justify-between items-center mb-6">
                                    <p className="text-start text-sm  font-bold  text-white">
                                        {card.date}
                                    </p>
                                    <p className="text-start text-sm text-primary  font-semibold">
                                        {card.webName}
                                    </p>
                                </div>
                                <h1 className="text-start text-xl  font-bold text-white">
                                    {card.title}
                                </h1>
                                <button
                                    onClick={() => window.open(card.path, '_blank')}
                                    className="bg-[#0F1013] text-primary mt-6 border-2 border-primary  h-10 w-full rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out"
                                >
                                    Read More
                                </button>
                            </div>
                        </div>
                    </MovingBorderButton>
                ))}
            </div>
        </div>
    );
};

export default News;
