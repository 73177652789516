"use client";
import React, { useState } from "react";

import {
    Backers,
    Footer,
    Introduction,
    PreSaleCountdown,
    Roadmap,
    Socials,
    Tokenomics,
    Web3TokenInfo,
    WhoUs
} from "../../components/Sections"
import Pool from "../../components/Sections/XPool";


export default function Staking() {

    return (
        <div className="flex flex-col space-y-16">
            <h2 className="text-primary text-center lg:text-6xl max-sm:text-2xl  sm:text-2xl capitalize font-glaser">Web3D Staking DApp </h2>
            <div className="flex flex-row flex-wrap items-center justify-center   p-8    gap-4">
                <Pool pid='0'  min="30_000" max ="75_000" apy='8%' />
                <Pool pid='1'  min="75_000" max ="100_000" apy='10%' />
                <Pool pid='2'  min="100_000" max ="200_000" apy='12%' />
                <Pool pid='3'  min="200_000" max ="400_000" apy='13%' />
                <Pool pid='4'  min="400_000" max ="800_000" apy='15%' />
                <Pool pid='5'  min="800_000" max ="8_000_000" apy='17%' />
            </div>
            <Footer />
        </div>
    );
}