import React from "react";
import "./css/Tokenomics.css";

const Tokenomics = () => {
    return (
        <section id="web3d">
            <div className="tokenomics">
                <h1 class="tokenomics-title text-primary text-3xl lg:text-6xl font-main mb-5 mt-5">
                    Tokenomics
                </h1>

                <div className="tokenomics-container">
                    <div className="left-section">
                        <img
                            src="./images/Tokenomics-01.svg"
                            alt="Tokenomics Pie Chart"
                            className="pie-chart"
                        />

                        <table className="tokenomics-table">
                            <tbody>
                            <tr>
                                <td>Max Supply</td>
                                <td>1,000,000,000 Web3D</td>
                            </tr>
                            <tr>
                                <td>Web3D Allocated for Sale, Listing, and Liquidity</td>
                                <td>300,000,000 Web3D</td>
                            </tr>
                            <tr>
                                <td>Team Token Vesting</td>
                                <td>200,000,000 Web3D</td>
                            </tr>
                            <tr>
                                <td>
                                    Business Development, CEX Listing, Marketing, Grants,
                                    Research
                                </td>
                                <td>270,000,000 Web3D</td>
                            </tr>
                            <tr>
                                <td>Stake and Community Rewards</td>
                                <td>230,000,000 Web3D</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="right-section">
                        <div className="info-cards">
                            <div className="info-card">
                                <p>Pre-Sale</p>
                                <h3>300,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Reward</p>
                                <h3>230,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Stake Rewards</p>
                                <h3>200,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Community Rewards</p>
                                <h3>30,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Staff Costs</p>
                                <h3>80,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Global Partnerships</p>
                                <h3>20,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>CEX Listing & Liquidity</p>
                                <h3>50,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Grant Programs & Research Center</p>
                                <h3>100,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Grant Programs</p>
                                <h3>50,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Research Center</p>
                                <h3>50,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Marketing & Business Development</p>
                                <h3>100,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Marketing</p>
                                <h3>50,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Business Development</p>
                                <h3>50,000,000 Web3D</h3>
                            </div>

                            <div className="info-card">
                                <p>Core Team</p>
                                <h3>100,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Advisors</p>
                                <h3>20,000,000 Web3D</h3>
                            </div>
                            <div className="info-card">
                                <p>Additional Research Center Staff Costs</p>
                                <h3>80,000,000 Web3D</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tokenomics;
