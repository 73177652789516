import {register, SocialIcon} from 'react-social-icons';


const SOCIALS = [
    {logo: 'github', path: 'https://github.com/web3decision'},
    {logo: 'twitter', path: 'https://x.com/web3decision'},
    {logo: 'telegram', path: 'https://t.me/web3decision'},
    {logo: 'facebook', path: 'https://www.facebook.com/web3decisioncom'},
];


register('test', {
    color: 'red',
    path: 'M0 0v31l13.707-14.171L9.546 12.4l-3.794 3.416V6.833l8.934 8.983 9.3-8.983v9.996L19.949 12.4l-4.528 4.429L29.984 31V0h-8.812l-6.486 6.833L8.812 0H0Z',
});


const Footer = () => {
    return (
        <footer className="mt-auto p-4 bg-darkbackground z-[10] text-[#b4b4b4] text-sm font-normal capitalize w-full">
            {/* Logo Section */}
            <div className="grid grid-cols-1 gap-y-8 lg:gap-y-4 place-items-center">
                <div className="flex justify-center h-10">
                    <img src="/images/cd_logo.png" alt="logo" className="h-full"/>
                </div>

                {/* Icon Section */}
                <div className="flex flex-wrap justify-center items-center gap-4">
                    <img src="/icons/Google-Ai-Gemini.png" alt="Google AI" className="h-8"/>
                    <img src="/icons/openai.png" alt="OpenAI" className="h-6"/>
                    <img src="/icons/dextools.png" alt="Dextools" className="h-6"/>
                    <img src="/icons/full-pancakeswap-logo.png" alt="Pancakeswap" className="h-6"/>
                    <img src="/icons/graphql.png" alt="GraphQL" className="h-12"/>
                </div>
            </div>

            {/* Divider */}
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700"/>

            {/* Footer Bottom Section */}
            <div className="flex flex-col sm:flex-row items-center justify-between space-y-6 sm:space-y-0">
                {/* Social Links */}
                <div className="flex space-x-4">
                    {SOCIALS.map((item) => (
                        <button
                            key={item.path}
                            className="hover:border-2 border-primary hover:bg-primary transition-colors duration-300 ease-in-out rounded-lg cursor-pointer"
                            onClick={() => window.open(item.path, '_blank')}
                        >
                            <SocialIcon
                                url={item.path}
                                network={item.logo}
                                style={{height: 40, width: 40}}
                                fgColor="#212020"
                                bgColor="#02e7ff"

                            />
                        </button>
                    ))}

                    <button
                        onClick={() => window.open("https://coinmarketcap.com/community/profile/web3decision/", '_blank')}
                        className=" rounded-full h-[40px] w-[40px] bg-[#02e7ff] p-1 hover:border-2 border-primary hover:bg-primary transition-colors duration-300 ease-in-out hover:rounded-lg cursor-pointer">
                        <img src="/icons/coinmarket.svg"/>
                    </button>

                </div>

                {/* Text Section */}
                <div className="text-center sm:text-right font-rajdani">
                    <p className="text-sm font-semibold text-white">
                        Web3 Decision, 2024
                    </p>
                    <p className="text-xs font-semibold text-white">
                        Inc,{' '}
                        <a href="#" target="_blank" className="cursor-pointer underline">
                            Privacy Policy Terms & Conditions
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
