import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { useSigner } from 'wagmi'
import { ERC20 } from '../../contracts/ERC20'
import { XStaking } from '../../contracts/XStaking'
import { toast } from 'react-hot-toast'
import { ethers } from 'ethers';
import Axios from 'axios';
import { fetchBalance } from '@wagmi/core'
import StakingPopup from './StakingPopup'
import { SocialIcon } from 'react-social-icons';
import { PinContainer } from '../Cards/PinContainer'

const Pool = ({ apy, pid, min, max }) => {


    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const { address, isConnected } = useAccount()
    const [userDeposited, setuserDeposited] = useState('0.00')
    const [balance, setBalance] = useState('0.00')
    const [userPending, setuserPending] = useState('0.00')
    const [isLocked, setisLocked] = useState(false);
    const [tvl, setTvl] = useState('0')
    const { data: signer, isError, isLoading } = useSigner();
    const handleOpenModal = () => setModalOpen("success")
    const DoWithdraw = async () => {
        const stakingContract = new XStaking(signer, '0xa8c51818d9c95b648b1105033139b696be1d7091')
        const depositedAmount = await stakingContract.userDeposited(pid, address)
        if (Number(depositedAmount) <= 0) {
            toast.error("You have low deposited WEB3D")
            return
        }
        handleOpenModal('loading')
        await stakingContract.withdraw(pid);
        setModalOpen(false)
        toast.success('Withdraw Successfully')
    }
    const DoStaking = async (amount, selectedDuration) => {
        try {
            if (!signer) return
            const coinContract = new ERC20(signer, '0x7ed9054c48088bb8cfc5c5fbc32775b9455a13f7')
            const stakingContract = new XStaking(signer, '0xa8c51818d9c95b648b1105033139b696be1d7091')
            const balance = await coinContract.balance(address)
            let allowedAmount = await coinContract.allowedAmount(address, '0xa8c51818d9c95b648b1105033139b696be1d7091')
            console.log('balance ', balance.toString())
            console.log('allowedAmount ', allowedAmount.toString())
            handleOpenModal('loading')

            while (parseFloat(allowedAmount) < Number(amount)) {
                toast.error(`Please allow WEB3D tokens`)
                await coinContract.approve('0xa8c51818d9c95b648b1105033139b696be1d7091')
                allowedAmount = await coinContract.allowedAmount(address, '0xa8c51818d9c95b648b1105033139b696be1d7091')
                console.log('allowedAmount ', allowedAmount.toString())
            }
            setModalOpen(false)
            const depositAmount = ethers.utils.parseEther(amount.toString())
            toast.success('Please wait for transaction....')
            await stakingContract.stake(pid, depositAmount, selectedDuration)
            setModalOpen(false)
            toast.success('Deposited Successfully')
            handleOpenModal('success')
            await updateUserInfo();
        } catch (txError) {
            console.log("txError :" , txError)
            setModalOpen(false)
            toast.error("Transaction Reverted or Rejected")
        }

    }
    const updateUserInfo = async () => {
        if (!signer) return
        console.log("runing updateUserInfo ", signer)

        const stakingContract = new XStaking(signer, '0xa8c51818d9c95b648b1105033139b696be1d7091')
        const coinContract = new ERC20(signer, '0x7ed9054c48088bb8cfc5c5fbc32775b9455a13f7')
        const balance = await coinContract.balance(address)
        console.log("balance fetched : ", balance, address)
        setBalance(String(balance));
        const depositedAmount = await stakingContract.userDeposited(pid, address)
        const getPending = await stakingContract.getPending(address , pid)
        const checkLock = await stakingContract.isLocked(address)
        setisLocked(checkLock);
        console.log("checkLock :", checkLock)
        setuserPending(String(getPending))
        setuserDeposited(String(depositedAmount))
    }
    const updateXPoolInfo = async () => {
        try {
        const res = await Axios.get(
            `https://api.web3decision.com/getTvl?pid=${pid}`
        )
        const tvl = res.data.tvl;
        setTvl(String(tvl));
        } catch (txError) {
            console.log("error : " , txError)
        }

    }
    useEffect(() => {
        setInterval(async function () {
            await updateXPoolInfo();
        }, 10000)
    }, [])
    useEffect(() => {
        (async function () {
            try {
                await updateUserInfo();
            } catch (e) {
                console.log(e);
            }
        })();
    }, [signer]);
    return (
        <>
            <div
                className=" z-10 flex items-center justify-center  "
            >
                <div className='p-4 border-2 rounded-2xl'>
                    <div className='flex space-x-2 z-[10]  items-center'>
                        <div className='w-10 h-10 rounded-full'>
                            <img src={'/logo.png'} />
                        </div>
                        <span className="flex items-center text-sm text-white  me-3 font-rajdani font-bold">
                            Available
                            <span className="flex w-2.5 h-2.5 bg-green-500 rounded-full ms-1.5 flex-shrink-0"></span>
                        </span>
                    </div>

                    <div className='flex flex-col  p-2 w-full'>

                        <div className='flex justify-between p-3'>
                            <h2 className="text-white ">APY :</h2>
                            <h2 className="text-white ml-2">{apy}</h2>
                        </div>
                        <div className='flex justify-between p-3'>
                            <h2 className="text-white ">Minimum Deposit :</h2>
                            <h2 className="text-white ml-2">{min}</h2>
                        </div>
                        <div className='flex justify-between p-3'>
                            <h2 className="text-white ">Maximum Depsoit :</h2>
                            <h2 className="text-white ml-2">{max}</h2>
                        </div>
                        <div className='flex justify-between p-3'>
                            <h2 className="text-white ">TVL :</h2>
                            <h2 className="text-white ml-2">{tvl}</h2>
                        </div>
                        {isConnected && (
                            <>
                                <div className='flex justify-between p-3'>
                                    <h2 className="text-white ">Your deposit :</h2>
                                    <h2 className="text-white ml-2">{userDeposited}</h2>
                                </div>
                                <div className='flex justify-between p-3'>
                                    <h2 className="text-white ">Your Reward: :</h2>
                                    <h2 className="text-white ml-2">{userPending}</h2>
                                </div>
                            </>
                        )}

                    </div>˝
                    {isConnected && (
                        <div className="flex  gap-2 w-full justify-between mt-2 mb-2">
                            <button disabled={!isConnected} onClick={() => setIsPopupOpen('stake')} className="bg-[#0F1013] text-primary  border-2 border-primary  h-10 w-[130px] rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out"
                            >
                                Deposit
                            </button>
                            <button onClick={() => toast.error("Currently Claim is lock")} className="bg-[#0F1013] text-primary  border-2 border-primary  h-10 w-[130px]  rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out">
                                Claim
                            </button>
                        </div>
                    )}
                    <div className='flex justify-between p-3'>
                        <p className='text-sm font-semibold text-white'>Note* : Pool Staked Period one year</p>

                    </div>
                </div>

            </div>
            <StakingPopup isPopupOpen={isPopupOpen === 'stake' ? true : false} onClose={() => setIsPopupOpen(false)} onSubmitForm={(amount, selectedDuration) => DoStaking(amount, selectedDuration)} title='Deposit' balance={balance} min={min} max={max}/>
        </>


    );
};

export default Pool;